import { Injectable } from '@angular/core';
import { ScStorageService } from '../storage/sc-storage.service';
import { NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScHttpService } from '../http/sc-http.service';
import { DatePipe } from '@angular/common';
import { ServerURLS, EnvironmentConstants } from 'src/environments/environment';
import { BehaviorSubject, of } from 'rxjs';
import * as html2pdf from 'html2pdf.js';
import { EmailDocumentComponent } from 'src/app/modules/commons/email-document/email-document.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ScCommonService {
  public stapeTwoDara={};
  customerId: any = sessionStorage.getItem('_admincustomerId') ? sessionStorage.getItem('_admincustomerId') : null;
  // customerName: any = sessionStorage.getItem('_admincustomerName') ? sessionStorage.getItem('_admincustomerName') : null;
  partnerId: any = sessionStorage.getItem('_adminpartnerId') ? sessionStorage.getItem("_adminpartnerId") : null;
  orderDetailProbill: any;
  bolResultData: any;
  quoteId: any;
  totalItemBol: number;
  declaredBol: number;
  dimensionBOL: any;
  bolInvoiceDetails: any;
  printInvoiceFromInvoices: boolean = false;
  carrierRates: any;
  currencyInSaleRep: any = '';
  shipperZIPCode:any;
  consigneeZIPCode:any;
  private paymentInfoData$ = new BehaviorSubject<any>({});
  getpaymentInfoData$ = this.paymentInfoData$.asObservable();
  bolId:any
  commResultData:any;

  public data = new BehaviorSubject<any>({
    customerStatus: '',
    customerName: '',
    carrierContrat: '',
    toggle: false,
    appLoader: false
  })
  public orderDetailPageData=new BehaviorSubject<any>({
    orderStatus:''
  })
  public dashboardArray=new BehaviorSubject<any>({
    sideValue:[]
  })
  public currentData: any;
  public orderDetailCurrentData:any;
  public dashboardData:any
  // public PARTNERID:any;

  sharedData:any;
  saveBolFormData: any;
  saveCommInvFormData: any;
  saveCarrierContractFormData: any;
  carrierContractData: any;
  currentCarrier: any;

  constructor(private storageService: ScStorageService, private scHttpService: ScHttpService, private datePipe: DatePipe, private modalService: NgbModal, private toastr: ToastrService, private router: Router, private _http:HttpClient) {
    this.currentData = this.data.asObservable();
    this.currentData.subscribe((data:any)=>{
      this.sharedData=data
    })
    this.orderDetailCurrentData=this.orderDetailPageData.asObservable();
    this.dashboardData=this.dashboardArray.asObservable();
  }
  setPaymentInfoData(product: any) {
    this.paymentInfoData$.next(product);
  }
  removeQuoteStorage() {
    this.storageService.removeBucket("quote");
    this.storageService.removeBucket("items");
    this.storageService.removeBucket("shipment");
    this.storageService.removeBucket("quoteService");
  }

  isAtLeast30MinutesMore(hour: number, minute: number): boolean {
    const now = new Date();
    const inputTime = new Date();
    inputTime.setHours(hour, minute, 0);

    const diff = inputTime.getTime() - now.getTime();
    return diff >= 30 * 60 * 1000; // Check if the difference is at least 30 minutes (in milliseconds)
  }
  
  formatDate(d: NgbDate): string {

    if (d)
      return [
        d.year,
        (d.month < 10 ? ('0' + d.month) : d.month),
        (d.day < 10 ? ('0' + d.day) : d.day)
      ].join('-');

    else return null;
  }

  formatNgBDate(date: string) {
    let dt = new Date(date);
    let ngbDateStruct = { day: dt.getUTCDate(), month: dt.getUTCMonth() + 1, year: dt.getUTCFullYear() };
    return ngbDateStruct;
  }

  getShipperConsigneeDetails(addrType){
    let urlforShipperConsignee = `${ServerURLS.getdefaultShipper.action}?customerId=${this.customerId}&addrType=${addrType}`;
    return this._http.get(urlforShipperConsignee);
  }

  removeShipperConsigneeDetails(addrType){
    let obj={};
    let addrTypeVal;
    if(addrType=='shipper'){
      addrTypeVal = 'SHPR';
    }
    else {
      addrTypeVal = 'CNSE';
    }
    let urlforShipperConsignee = `${ServerURLS.removeShipperConsignee.action}?isRemoved=true&customerId=${this.customerId}&addrType=${addrTypeVal}`;
    return this._http.put(urlforShipperConsignee,obj);
  }
  

  changedateformat(date) {
    let split = date.split('-');
    let dt = new Date(split[2], split[1] - 1, split[0]);
    let date1 = this.datePipe.transform(dt, "yyyy-MM-dd");
    return date1;
  }

  getLocalProfileImage() {
    return this.storageService.getBucket("profileImage");
  }
  dateFormatter(fromDate: any, toDate: any) {
     
    let fromDateObj = {
      date: '',
      month: '',
      year: ''
    }
    let toDateObj = {
      date: '',
      month: '',
      year: ''
    }
    fromDateObj.year = fromDate.year;
    toDateObj.year = toDate.year;
    let FROMDATE = fromDate.day;
    let FROMMONTH = fromDate.month;
    if (FROMDATE < 10) {
      FROMDATE = "0" + FROMDATE
      fromDateObj.date = FROMDATE
    } else {
      fromDateObj.date = FROMDATE
    }
    if (FROMMONTH < 10) {
      FROMMONTH = "0" + FROMMONTH
      fromDateObj.month = FROMMONTH
    } else {
      fromDateObj.month = FROMMONTH
    }

    let TODATE = toDate.day;
    let TOMONTH = toDate.month;
    if (TODATE < 10) {
      TODATE = "0" + TODATE;
      toDateObj.date = TODATE;
    } else {
      toDateObj.date = TODATE;
    }
    if (TOMONTH < 10) {
      TOMONTH = '0' + TOMONTH;
      toDateObj.month = TOMONTH
    } else {
      toDateObj.month = TOMONTH;
    }
    // console.log(fromDateObj, toDateObj)
    return [fromDateObj, toDateObj]
  }
  getProfileImage(id): any {

    let promise1 = this.scHttpService.getHttpService(ServerURLS.getImage.action + "?id=" + id, '');
    promise1.then(value => {
      let image = EnvironmentConstants.NO_PROFILE_IMAGE;
      if (value["data"]) {
        image = value["data"]["downloadLink"];

      }
      this.storageService.saveBucket("profileImage", image);

    });

    return promise1;

  }
  getShipmentLabelUrls(orderId): any {
    let promise = this.scHttpService.getHttpService(ServerURLS.getOrderLabel.action + '?orderId=' + orderId, { withCredentials: true });
    return promise;
  }

  async dialogOpenForEmail(requiredDataObj) {

    const {modelToOpn, action, defaultEmail, quoteId, orderId, bolDocId, customOrderId, commInvDocId} = requiredDataObj;

    if (modelToOpn === "addFav") {
      // const modalRef = this.modalService.open(AddFavoriteComponent, {size: 'lg',centered: false,backdrop: true, windowClass: 'my-custom-modal3'});
      // this.router.events.subscribe((val) => {
      //   modalRef.close();
      // });
      // modalRef.componentInstance.quoteId = quoteId;
      // modalRef.componentInstance.orderId = this.orderId;
      // modalRef.result.then((result) => {
      //   if(result['success'] == true){
      //     this.toastr.success('Order saved as a favorite.', 'Success', {
      //       timeOut: 30000,
      //     });
      //     localStorage.setItem("fav_"+quoteId, "true");
      //   }
      // });
    }
    if (modelToOpn === "emailDoc") {
      // if (localStorage.getItem("com_documentId_" + quoteId) == null && action == "commercial") {
      //   this.toastr.error('First create commercial invoice.', 'Please', {
      //     timeOut: 30000,
      //   });
      //   return;
      // }
      const requiredDataObjToSavePdf = {
        quoteId: quoteId,
        orderId: orderId,
        bolDocId: bolDocId,
        customOrderId: customOrderId
      }
      if(action == 'bol'){
        this.saveBolFormData = await this.saveBolPdf(requiredDataObjToSavePdf);
      }
      else if(action == 'invoice'){
        this.saveBolFormData = await this.saveBolPdf(requiredDataObjToSavePdf);
      }
      else if(action == 'consolidatedInvoice'){
        this.saveBolFormData = await this.saveBolPdf(requiredDataObjToSavePdf);
      }
      else if(action == 'commercial'){
        this.saveCommInvFormData = await this.saveCommercialPdf(requiredDataObjToSavePdf);
      }else if(action == 'carrier-contract'){
        this.saveCarrierContractFormData = await this.saveCarrierContractPdf(requiredDataObjToSavePdf);
      }
      const modalRef = this.modalService.open(EmailDocumentComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'my-custom-modal3' });
      this.router.events.subscribe((val) => {
        modalRef.close();
      });
      modalRef.componentInstance.bolDocId = bolDocId;
      modalRef.componentInstance.commInvDocId = commInvDocId;
      modalRef.componentInstance.action = action;
      modalRef.componentInstance.defaultEmail = defaultEmail;
      modalRef.componentInstance.saveBolFormData = this.saveBolFormData;
      modalRef.componentInstance.saveCommInvFormData = this.saveCommInvFormData;
      modalRef.componentInstance.saveCarrierContractFormData = this.saveCarrierContractFormData;
      modalRef.result.then((result) => {
        if (result.success) {
          this.toastr.success('Email sent successfully.', 'Success', {
            timeOut: 10000,
          });
        } else {
          this.toastr.error('Error, While sending email.', 'Oops', {
            timeOut: 10000,
          });
        }
      });
    }
  }

  async saveCommercialPdf(requiredDataObj):Promise<FormData> {
    // let currentcomp = this;
    this.sharedData.appLoader = true;
    // await html2canvas(document.querySelector('#DownloadCommercialPdfData'), { useCORS: false }).then(function (canvas) {
    //   var imgWidth = 210;
    //   var pageHeight = 295;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight;
    //   let img = new Image();
    //   img.src = canvas.toDataURL('image/png');
    //   img.onload = () => {
    //     var position = 0;
    //     let pdf = new jsPDF('p', 'mm');
    //     pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
    //     heightLeft -= pageHeight;
    //     while (heightLeft >= 0) {
    //       position = heightLeft - imgHeight;
    //       pdf.addPage();
    //       pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
    //       heightLeft -= pageHeight;
    //     }
    //     var blobData = pdf.output("blob");
    //     currentcomp.sendCommercialPDFToServer(blobData);
    //   };
    // });

    var element = document.querySelector('#DownloadCommercialPdfData');
    var opt = {
      margin: 0.1,
      filename: "Commercial_Invoice_" + requiredDataObj.quoteId + ".pdf",
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, scale: 2, letterRendering: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', compressPDF: true },
      pageBreak: { mode: 'css', after: '.break-page' }
    };

    // New Promise-based usage:
    let worker = await html2pdf().set(opt).from(element).toPdf().output('blob').then((data: Blob) => {
      return data
    })

    return this.sendCommercialPDFToServer(worker, requiredDataObj);
  }
  sendCommercialPDFToServer(pdfContent, requiredDataObj) {
    // console.log( this.displayLoader)
    const formdata: FormData = new FormData();
    formdata.append("orderId", requiredDataObj.orderId);
    formdata.append("quoteId", requiredDataObj.quoteId);
    // formdata.append("commInvId", this.commercialInvoiceId)

    var date = new Date().valueOf();

    const blobFile = new Blob([pdfContent], {
      type: 'application/pdf',
    });



    var dummyFile = new File([pdfContent], "COM_" + requiredDataObj.customOrderId + ".pdf");
    formdata.append("file", dummyFile);
    this.sharedData.appLoader=false;
    return formdata;

    // this.docService.saveCommercialPdf(formdata).subscribe(result => {
    //   this.displayLoader = false;
    //   this.toastr.success('Commercial invoice PDF Saved Successfully.', '', {
    //     timeOut: 5000,
    //   });
    // }, error => {
    //   if (error.status == 401) {
    //     localStorage.clear();
    //     this.router.navigate(['/signin']);
    //   }
    //   this.displayLoader = false;
    //   this.toastr.error('Something Went wrong while saving Commercial Invoice.', 'Please try again.', {
    //     timeOut: 5000,
    //   });
    //   this.displayLoader = false;
    // });
  }

  async saveBolPdf(requiredDataObj):Promise<FormData> {
    this.sharedData.appLoader = true;
    // let currentcomp = this;
    // html2canvas(document.querySelector('#content'),{useCORS:false}).then(function(canvas) {
    //   var imgWidth = 210;
    //   var pageHeight = 295;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight;
    //   let img = new Image();
    //   img.src = canvas.toDataURL('image/png');
    //   img.onload = function () {
    //     var position = 0;
    //     let pdf = new jsPDF('p', 'mm');
    //     pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
    //     heightLeft -= pageHeight;
    //     while (heightLeft >= 0) {
    //       position = heightLeft - imgHeight;
    //       pdf.addPage();
    //       pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
    //       heightLeft -= pageHeight;
    //     }
    //     var blobData = pdf.output("blob");
    //     currentcomp.sendPDFToServer(blobData);
    //   };
    // });
    var element = document.querySelector('#contentbol');
    var opt = {
      margin: 0.1,
      filename: "BOL_" + requiredDataObj.quoteId + ".pdf",
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, scale: 1, letterRendering: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', compressPDF: true },
      pageBreak: { mode: 'css', after: '.break-page' }
    };

    // New Promise-based usage:
    let worker = await html2pdf().set(opt).from(element).toPdf().output('blob').then((data: Blob) => {
      return data
    })
    // console.log(worker);
    return this.sendBolPDFToServer(worker, requiredDataObj);
  }
  downloadFile(url: string, fileName: string) {
    this._http.get(url, { responseType: 'blob' }).subscribe((blob) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }

  sendBolPDFToServer(pdfContent, requiredDataObj) {
    const formdata: FormData = new FormData();
    formdata.append("documentId", requiredDataObj.bolDocId);
    formdata.append("orderId", requiredDataObj.orderId);

    var date = new Date().valueOf();
    const blobFile = new Blob([pdfContent], {
      type: 'application/pdf',
    });

    var dummyFile = new File([pdfContent], "BOL_" + requiredDataObj.bolDocId + ".pdf");
    formdata.append("file", dummyFile);

    this.sharedData.appLoader=false;
    return formdata;
    // this.docService.savePdf(formdata).subscribe(result => {
    //   this.displayLoader = false;
    //   this.toastr.success('PDF Saved Successfully.', '', {
    //     timeOut: 10000,
    //   });
    // }, error => {
    //   if (error.status == 401) {
    //     this.router.navigate(['/signin']);
    //   }
    //   this.displayLoader = false;
    //   this.toastr.error('Something Went wrong while saving PDF.', 'Please try again.', {
    //     timeOut: 10000,
    //   });
    //   this.displayLoader = false;
    // });

  }

  async saveCarrierContractPdf(requiredDataObj): Promise<FormData> {
    this.sharedData.appLoader = true;
    var element = document.querySelector('#contentContract');
  
    const opt = {
      filename: "CarrierContract_" + requiredDataObj.customOrderId + ".pdf",
      image: { type: 'jpeg' },
      html2canvas: { dpi: 300, scale: 1.2 },
      jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
      margin: [10, 10, 10, 10],
      header: function(currentPage, totalPages) {
        let text = currentPage > 1 ? `Carrier Confirmation ${requiredDataObj.customOrderId} (Continued)` : "";
        return {
          text: text,
          alignment: 'left',
          margin: [20, 20, 0, 0]
        };
      },
      footer: function(currentPage, totalPages) {
        return {
          text: `Page ${currentPage} of ${totalPages}`,
          alignment: 'right',
          margin: [0, 0, 10, 10]
        };
      }
    };
  
    // New Promise-based usage:
    let worker = await html2pdf().set(opt).from(element).toPdf().output('blob').then((data: Blob) => {
      return data;
    });
  
    return this.sendCarrierContractPDFToServer(worker, requiredDataObj);
  }
  


  sendCarrierContractPDFToServer(pdfContent, requiredDataObj) {

    const formdata: FormData = new FormData();
    formdata.append("orderId", requiredDataObj.orderId);

    var date = new Date().valueOf();
    const blobFile = new Blob([pdfContent], {
      type: 'application/pdf',
    });

    var dummyFile = new File([pdfContent], "Carrier-Contract_" + requiredDataObj.customOrderId + ".pdf");
    formdata.append("file", dummyFile);

    this.sharedData.appLoader=false;
    return formdata;

  }

  getpickUpList(type){
    return this._http.get(ServerURLS.getPickUpList.action+'?type='+type, { withCredentials: true });
  }

  getEmptyCarrierData(){
    return {
                "carrierId": "",
                "rates": [{
                  'chargeName':  '',
                'chargeCode':  '',
                'quantity': 0,
                'isAfs':  false,
                'totalCharge': '',
                "fscRate": 0,
                "isFscRatePecent": false
                }],
                "quoteProIdList": [],
                "carrierContractInfo": {
                    "arrangedWith": null,
                    "driverName": null,
                    "truckNumber": null,
                    "trailerNumber": null,
                    "carrierReference": null,
                    "equipmentClass": null,
                    "contractRate": 30,
                    "currencyCode": "CAD",
                    "contractInstruction": null
                },
                "insuranceInfo": [],
                // "contractRevenueInfo": [],
                "invReceiptGenerated": false
            }
  }
  setStapeTwoDara(key,value:any,quoteId,carrierDetails,contractRevenue,contractCost,profitPercentage,profit,chargeItemList,insauranceData){
    let paymentDetailsList:any=[];
    value.controls.paymentDetails.controls.forEach(element => {
      paymentDetailsList.push({
        chargeItem: element.controls.chargeItem.value,
        probill: element.controls.probill.value,
        chargeDescription: element.controls.chargeDescription.value,
        quantity:element.controls.quantity.value,
        price: element.controls.price.value,
        afs: element.controls.afs.value,
        typeOfafs: element.controls.typeOfafs.value,
        afsAmount: element.controls.afsAmount.value,
        totalCharge: element.controls.totalCharge.value,
        newLine: element.controls.newLine.value
      })
    });
    let tempcarrierId=key;
    if (!key.includes('&')) {
      tempcarrierId=key+"&"+value.controls.profileImageUrl.value
    }
    const obj={
      selectedProbill:key,
      carrierId: tempcarrierId,
      arrangedWith: value.controls.arrangedWith.value,
      trailerNumber: value.controls.trailerNumber.value,
      equipment: value.controls.equipment.value,
      driverName: value.controls.driverName.value,
      contractRate: value.controls.contractRate.value,
      contractRateFlag: value.controls.contractRateFlag.value,
      truckNumber: value.controls.truckNumber.value,
      carrierRef: value.controls.carrierRef.value,
      instruction:value.controls.instruction.value ,
      paymentDetails: paymentDetailsList,
      baseChanges:value.controls.baseChanges.value,
      fscAmount: value.controls.fscAmount.value,
      subtotal: value.controls.subtotal.value,
      balancedue: value.controls.balancedue.value,
      additionalCurrency: value.additionalCurrency,
      carrierDetails:carrierDetails,
      contractRevenue:contractRevenue,
      contractCost:contractCost,
      profitPercentage:profitPercentage,
      profit:profit,
      chargeItemList:chargeItemList,
      isAllRequiredDataSet:false,
      quoteId:quoteId,
      insuranceCurrency: sessionStorage.getItem('_admincountryCurrency'),
      insuranceAmount: insauranceData.insuranceAmount,
      certificateNumber: insauranceData.certificateNumber,
      insuranceProvider: insauranceData.insuranceProvider,
      insuranceSelected: insauranceData.insuranceSelected,
    }
    if (key) {
    this.stapeTwoDara[key.split("&")[0]]=obj;
  }
  }
  
  setStapeTwoDaraFromService(key,value:any,carrierDetails,contractCost,profitPercentage,chargeItemList,carrierDetailList){
    let paymentDetailsList:any=[];
  if (value.rates.length>0) {
    value.rates.forEach(element => {
      paymentDetailsList.push({
        chargeItem: element.chargeName,
        probill: element.probill,
        chargeDescription: element.chargeName?element.chargeName:'',
        quantity:element.quantity,
        price: element.totalCharge/element.quantity,
        afs: element.afs,
        typeOfafs: element.isFscRatePecent,
        afsAmount: element.fscRate,
        totalCharge: element.totalCharge,
        newLine: false
      })
    });
    
  } else {
    paymentDetailsList.push({
      chargeItem: '',
      probill: '',
      chargeDescription: '',
      quantity:'',
      price:'',
      afs: false,
      typeOfafs: '',
      afsAmount: '',
      totalCharge: 0,
      newLine: false
    })
  }
  if (value.carrierId) {
    this.currentCarrier=carrierDetailList.find(c=>c.carrier_id==value.carrierId)
  }
  this.currentCarrier=this.currentCarrier?this.currentCarrier:{profile_image_download_url:"NA"}
    const obj={
      selectedProbill:key,
      quoteId:value.quoteId,
      carrierId: value.carrierId+(value.carrierId=='0aa510dd-8101-11ed-915f-4ea80e173030'?'&https://dev-shipcarte-docs.s3.amazonaws.com/1677054692560-BLACK.png':"&"+this.currentCarrier.profile_image_download_url),
      arrangedWith: value.carrierContractInfo?value.carrierContractInfo.arrangedWith:'',
      carrierRef: value.carrierContractInfo?value.carrierContractInfo.carrierReference:'',
      instruction:value.carrierContractInfo?value.carrierContractInfo.contractInstruction:'' ,
      driverName: value.carrierContractInfo?value.carrierContractInfo.driverName:'',
      truckNumber:value.carrierContractInfo? value.carrierContractInfo.truckNumber:'',
      trailerNumber: value.carrierContractInfo?value.carrierContractInfo.trailerNumber:'',
      equipment: value.carrierContractInfo?value.carrierContractInfo.equipmentClass:'',
      contractRate: value.carrierContractInfo.contractRate?value.carrierContractInfo.contractRate:'30',
      contractRateFlag: value.carrierContractInfo.currencyCode?value.carrierContractInfo.currencyCode:'CAD',

      paymentDetails: paymentDetailsList,
      baseChanges:value.baseChanges?value.baseChanges:'',
      fscAmount: value.fscAmount,
      subtotal: value.subtotal,
      balancedue: value.balancedue?value.balancedue:'',
      additionalCurrency: value.additionalCurrency? value.additionalCurrency:'CAD',
      carrierDetails:carrierDetails,
      contractRevenue:value.contractRev,
      contractCost:contractCost,
      profitPercentage:profitPercentage,
      profit:value.profit,
      chargeItemList:chargeItemList,
      isAllRequiredDataSet:false,
      insuranceCurrency:sessionStorage.getItem('_admincountryCurrency'),
      insuranceAmount: value.insuranceAmount,
      certificateNumber: value.certificateNumber,
      insuranceProvider: value.insuranceProvider,
      insuranceSelected: value.insuranceSelected,
      profileImageUrl:this.currentCarrier.profile_image_download_url

    }
    if (key) {
      this.stapeTwoDara[key.split("&")[0]]=obj;
    }
  }
  getStapeTwoData(key){
    const currentKey=key.split("&")[0]
    return this.stapeTwoDara[currentKey];
  }
  calculateTotalForAllProbill(){
    const totalBillDEtails={
      contractRevenue:0,
      contractCost:0,
      profitPercentage:0,
      profit:0
    }
    for (const key in this.stapeTwoDara) {
      if (this.stapeTwoDara.hasOwnProperty(key)) {
        const element = this.stapeTwoDara[key];
        totalBillDEtails.contractRevenue= totalBillDEtails.contractRevenue+parseFloat(element.contractRevenue);
        totalBillDEtails.contractCost= totalBillDEtails.contractCost+parseFloat(element.contractCost);
        totalBillDEtails.profit= totalBillDEtails.profit+parseFloat(element.profit);
      }
    }
    if (totalBillDEtails.contractCost != 0) {
    totalBillDEtails.profitPercentage=  (totalBillDEtails.profit /  totalBillDEtails.contractCost) * 100;
  }else{
    totalBillDEtails.profitPercentage=0;
  }
    // console.log(totalBillDEtails);
    return totalBillDEtails;
  }
  getContractInfo(quoteId){
    let urlforContractInfo= `${ServerURLS.getContractInfo.action}?parentQuoteId=${quoteId}`;
    return this._http.get(urlforContractInfo);
  }
  getContractInfoSummery(quoteId){
    let urlforContractInfo= `${ServerURLS.getContractInfoSummery.action}?quoteId=${quoteId}`;
    return this._http.get(urlforContractInfo);
  }
  removedUmMapedCarrier(probillNoAndQuoteIdMapping){
    for (const key in this.stapeTwoDara) {
      if (this.stapeTwoDara.hasOwnProperty(key)) {
        if(!probillNoAndQuoteIdMapping.hasOwnProperty(key)){
            delete this.stapeTwoDara[key];
        }
      }
    }
  }
  checkAllProbill(){
    let errRes=''
    for (const key in this.stapeTwoDara) {
      if (this.stapeTwoDara.hasOwnProperty(key)) {
        const element = this.stapeTwoDara[key];
        let isPaymentInvalidCount=0;
        element.paymentDetails.forEach((el) => {
          if (el.quantity > 0 && el.totalCharge > 0 && el.chargeItem !== '') {
          }else{
            isPaymentInvalidCount++;
          }
        })
        if (element.carrierId =='' || isPaymentInvalidCount>0) {
          element.isAllRequiredDataSet=false;
          if (!errRes) {
            errRes=key;
          }
        } else{
          element.isAllRequiredDataSet=true;
        }
      }
    }
  return errRes;
  }

  getProbillDetails(){
    const probillList= localStorage.getItem('probillList');
    return of({
      probill_list:JSON.parse(probillList)
    })
 
  }

  papulateSaveData(insuranceInfoList,selectedCarrierProbills){
    const res=[];
    for (const key in this.stapeTwoDara) {
      if (this.stapeTwoDara.hasOwnProperty(key)) {
        const element = this.stapeTwoDara[key];
        let dataObj = [];

        element.paymentDetails.forEach((el, index) => {
          let chargeName = el.chargeItem;
          let chargeCode = '';

          chargeCode = element.chargeItemList?element.chargeItemList.find(o => o.chargeName === chargeName):'';

          if (el.quantity > 0 && el.totalCharge > 0 && el.chargeItem !== '') {
            let obj = {
              'chargeName': el.chargeItem ? el.chargeItem : '',
              'chargeCode': chargeCode ? chargeCode["chargeId"] : '',
              'quantity': el.quantity ? el.quantity : 0,
              'isAfs': el.afs ? el.afs : false,
              'totalCharge': el.totalCharge ? el.totalCharge : '',
              "fscRate": el.afsAmount ? el.afsAmount : 0,
              "isFscRatePecent": el.typeOfafs === 'percentage' ? true : false
            }
            dataObj.push(obj)
          }
        })
        // need to updade
        const insuranceInfo=insuranceInfoList[key];
        const quoteProIdList=selectedCarrierProbills[key];
        const crId=element.carrierId.split("&")[0]
        let carrierContractObj = {
          "carrierId":crId,
          carrierContractInfo:{
            "arrangedWith": element.arrangedWith ? element.arrangedWith : '',
            "driverName": element.driverName?element.driverName: '',
            "truckNumber": element.truckNumber?element.truckNumber: '',
            "trailerNumber": element.trailerNumber?element.trailerNumber: '',
            "carrierReference": element.carrierRef?element.carrierRef: '',
            "equipmentClass": element.equipment?element.equipment: '',
            "contractRate": element.contractRate?element.contractRate: '30',
            "currencyCode": element.contractRateFlag?element.contractRateFlag: 'CAD',
            "contractInstruction": element.instruction?element.instruction: '',
          },
          insuranceInfo:insuranceInfo,
          quoteProIdList:quoteProIdList,
          "rates":dataObj,
         
        }
        if (quoteProIdList) {
          res.push(carrierContractObj);
        }
      }
    }
    return {carrierContractReq:res};
    
  }
  saveDraft(quoteData){
    let url = ServerURLS.draftSave.action;
    return this._http.post(url,quoteData);
  }
}
export class CustomDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (!value)
      return null
    let parts = value.split('-');
    return { month: +parts[1], day: +parts[2], year: +parts[0] } as NgbDateStruct

  }
  format(date: NgbDateStruct): string {
    return date ? ('0' + date.month).slice(-2) + "-" + ('0' + date.day).slice(-2) + '-' + date.year : null
  }
}
export class UnitedStatesDateParserFormatter extends NgbDateParserFormatter {

  parse(value: string): NgbDateStruct | null {
    if (value != null) {
      const parts = value.split('/');
      if (parts.length === 3 && this.isNumber(parts[0]) && this.isNumber(parts[1]) && this.isNumber(parts[2])) {
        return { month: parseInt(parts[0]), day: parseInt(parts[1]), year: parseInt(parts[2]) };
      }
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date && this.isNumber(date.day) && this.isNumber(date.month) && this.isNumber(date.year)
      ? `${this.padNumber(date.month)}-${this.padNumber(date.day)}-${date.year}`
      : '';
  }

  private isNumber(value: any): value is number {
    return !isNaN(parseInt(value));
  }

  private padNumber(value: number) {
    if (this.isNumber(value)) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }
}

